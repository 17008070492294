import classNames from "classnames";

import { MenuType } from "utils/menuTypeUtils";

import MenuTypeButton from "../MenuTypeButton";
import MenuTypeDisplay from "../MenuTypeDisplay";

const MenuTypeSelection = ({
  className,
  isDualLicense,
  onMenuTypeChange,
  showSelectInput,
}: {
  className?: string;
  isDualLicense: boolean;
  onMenuTypeChange?: (value: MenuType.Med | MenuType.Rec) => void;
  showSelectInput: boolean;
}) =>
  isDualLicense ? (
    <div className={classNames("text-right", className)}>
      {showSelectInput ? (
        <MenuTypeButton onMenuTypeChange={onMenuTypeChange} />
      ) : (
        <MenuTypeDisplay />
      )}
    </div>
  ) : (
    <></>
  );

export default MenuTypeSelection;
